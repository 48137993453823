import { stickyElem } from '../helper-function/stickyElem';

export const initStickyMenu = () => {
    // VARS
    const featuresMenu = document.querySelector('[data-features-menu]');
    const featureMenuBlock = document.querySelector('[data-features-menu-block]');
    if (!featuresMenu || !featureMenuBlock) return;

    // INITS
    stickyElem(featureMenuBlock, 0, setStyleStickyMenu, removeStyleStickyMenu);

    // FUNCTIONS
    function setStyleStickyMenu() {
        featureMenuBlock.style.marginBottom = featuresMenu.scrollHeight + 'px';
        featuresMenu.classList.add('features-menu--sticky');
    }

    function removeStyleStickyMenu() {
        featureMenuBlock.style.marginBottom = '';
        featuresMenu.classList.remove('features-menu--sticky');
    }
};

export function initJivosite() {
    if (Boolean(window.spystealthJivoChatHash)) {
        setTimeout(function () {
            const headID = document.getElementsByTagName('head')[0];
            const newScript = document.createElement('script');
            newScript.type = 'text/javascript';
            newScript.src = `//code.jivosite.com/widget/${window.spystealthJivoChatHash}`;
            headID.appendChild(newScript);
        }, 5000);
    }
}

export const initMenuHighlight = () => {
    // VARS
    const sections = document.querySelectorAll('[data-section]');
    const offset = 200;

    // LISTENERS
    window.addEventListener('scroll', handleOnScrollPage, false);

    // HANDLERS
    function handleOnScrollPage() {
        highlightMenu();
    }

    // FUNCTIONS
    function highlightMenu() {
        if (window.innerWidth < 1030 || !sections.length) return;

        sections.forEach((section, index) => {
            const top = section.offsetTop - offset;
            const bottom = top + section.clientHeight;
            const scroll = window.scrollY;
            const id = section.getAttribute('id');
            const current = document.querySelector(`[data-highlight-link="#${id}"]`);

            scroll > top && scroll < bottom ? current.classList.add('active') : current.classList.remove('active');
        });
    }
};

export const initHamburger = () => {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const headerMenu = document.querySelector('[data-header-menu]');
    if (!hamburger) return;

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger, false);

    // HANDLERS
    function handleOnClickHamburger() {
        toggleHamburgerAndMenu();
    }

    // FUNCTIONS
    function toggleHamburgerAndMenu() {
        hamburger.classList.toggle('header-navigation__btn--active');
        headerMenu.classList.toggle('header__menu--active');

        if (window.innerWidth < 770) {
            this.classList.contains('header-navigation__btn--active') ? disableScroll() : enableScroll();
        }
    }
};

export const initTabs = () => {
    // VARS
    const tabToggles = document.querySelectorAll('[data-tab-toggle]');
    if (!tabToggles.length) return;

    // LISTENERS
    tabToggles.forEach((toggle) => {
        toggle.addEventListener('click', handleOnClickTabToggle, false);
    });

    // HANDLERS
    function handleOnClickTabToggle() {
        const tab = this.closest('[data-tab]');
        const index = this.dataset.tabToggle;

        changeActiveToggle(this, tab);
        changeActiveBody(index, tab);
    }

    // FUNCTIONS
    function changeActiveToggle(toggle, tab) {
        if (toggle.classList.contains('active')) return;

        tab.querySelector('.active[data-tab-toggle]').classList.remove('active');
        toggle.classList.add('active');
    }

    function changeActiveBody(index, tab) {
        if (tab.classList.contains('show')) return;

        tab.querySelector('.show[data-tab-body]').classList.remove('show');
        tab.querySelector(`[data-tab-body="${index}"]`).classList.add('show');
    }
};

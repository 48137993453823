import { initHamburger } from './components/initHamburger';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initTabs } from './components/initTabs';
import { initStickyMenu } from './components/initStickyMenu';
import { initMenuHighlight } from './components/initMenuHighlight';
import { initScrollLocker } from './assets/scrollLocker';
import { initForms } from './components/initForms';
import { initJivosite } from './components/initJivosite';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initAppendHiddenInputsToPlanForms } from '../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initScrollLocker();
    initAppendHiddenInputsToPlanForms();
    initHamburger();
    initScrollToAnchor();
    initTabs();
    initStickyMenu();
    initMenuHighlight();
    initForms();
    initJivosite();
}
